<script lang="ts" setup>
// todo: fix cookies layer
const { isModalActive } = useCookieControl()
const { platform } = useAppConfig()
</script>

<template>
  <div bg-neutral-900 dark:bg-neutral-50 text-neutral-400 w-full>
    <div fubex-container flex flex-col sm:flex-row items-center sm:justify-between gap-32px paragraph-md pt="32px" pb="80px" w-full border-t-1 border-neutral-700 dark:border-neutral-200>
      <div flex flex-col sm:flex-row items-center gap-24px>
        <NuxtLink :data-test="getCypressId('footer-gdpr')" :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" footer-link>
          Ochrana os. údajů (GDPR)
        </NuxtLink>
        <NuxtLink :data-test="getCypressId('footer-vop')" :to="`${platform.domain}/docs/vop.pdf`" target="_blank" footer-link>
          Obchodní podmínky
        </NuxtLink>
        <div :data-test="getCypressId('cookie-settings')" footer-link @click="isModalActive = true">
          Nastavení cookies
        </div>
      </div>
      <div flex flex-col sm:flex-row items-center gap-4px>
        <p>© Copyright {{ new Date().getFullYear() }}, Všechna práva vyhrazena</p>
      </div>
    </div>
  </div>
</template>
