<script lang="ts" setup>
const { isLogged, isAdmin, isInspector } = storeToRefs(useUser())

const { navigation } = useNavigation()
</script>

<template>
  <div :data-test="getCypressId('footer')" w-full bg-neutral-900 class="light">
    <div
      fubex-container flex items-center w-full text-neutral-50 px-16px
      flex-col
      sm="flex-row flex-nowrap  items-start justify-between" my="40px sm:54px"
    >
      <div
        flex flex-col gap-40px
        sm="flex-row justify-between grow-1"
        lg="justify-between gap-80px"
        mt-40px sm:mt-16px
      >
        <div flex flex-col items-center sm:items-start gap-16px>
          <h3 :data-test="getCypressId('footer-spolecnost')" heading6 font-700>
            Bitbeli
          </h3>
          <NuxtLink :data-test="getCypressId('footer-about')" to="/o-nas" footer-link>
            O nás
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-faq')" to="/faq" footer-link>
            Nejčastější otázky
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-kurzovni-listek')" to="/kurzovni-listek" footer-link>
            Kurzovní lístek
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-blog')" to="/blog" footer-link>
            Blog
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-contact')" to="/kontakt" footer-link>
            Kontakty
          </NuxtLink>
        </div>
        <div flex flex-col items-center sm:items-start gap-16px>
          <h3 :data-test="getCypressId('footer-pro-firmy')" heading6 font-700>
            Služby
          </h3>
          <NuxtLink :data-test="getCypressId('footer-company')" to="/nakup-kryptomen-na-firmu" footer-link>
            Nákup kryptoměn na firmu
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-whitelabel')" to="/smenarensky-software" footer-link>
            Směnárenský software
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-invoice')" to="/platba-faktur-kryptomenami" footer-link>
            Platba faktur kryptoměnami
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-exchange')" to="/smena-kryptomen" footer-link>
            Směna kryptoměn
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-dca')" to="/smena-kryptomen#pravidelne-sporeni" footer-link>
            Pravidelné spoření
          </NuxtLink>
          <NuxtLink :data-test="getCypressId('footer-affiliate')" to="/affiliate" footer-link>
            Affiliate
          </NuxtLink>
        </div>
        <div flex flex-col items-center sm:items-start gap-16px>
          <NuxtLink to="/o-kryptomenach" :data-test="getCypressId('footer-pro-firmy')" heading6 font-700>
            O kryptoměnách
          </NuxtLink>
          <NuxtLink v-for="item in navigation.find((group) => group.key === 'o-kryptomenach')!.subMenu ?? []" :key="item.key" :data-test="getCypressId(`footer-o-kryptomenach-${item.key}`)" :to="item.href" footer-link>
            {{ item.label }}
          </NuxtLink>
        </div>

        <div
          v-if="isLogged" flex flex-col items-center sm:items-start gap-16px
        >
          <h3 heading6 font-700>
            Zákaznická sekce
          </h3>
          <NuxtLink to="/profile/overview" footer-link>
            Přehled
          </NuxtLink>
          <NuxtLink to="/profile/account" footer-link>
            Nastavení
          </NuxtLink>
          <NuxtLink to="/profile/exchange" footer-link>
            Směna
          </NuxtLink>
          <NuxtLink to="/profile/history" footer-link>
            Historie směn
          </NuxtLink>
          <NuxtLink to="/logout" footer-link>
            Odhlásit se
          </NuxtLink>
          <NuxtLink v-if="isInspector" to="/profile/inspector/" footer-link>
            Inspektor
          </NuxtLink>
          <NuxtLink v-if="isAdmin" to="/profile/admin/bank-accounts" footer-link>
            Administrace
          </NuxtLink>
        </div>
        <div v-else flex flex-col items-center sm:items-start gap-16px paragraph-medium>
          <h3 :data-test="getCypressId('footer-muj-ucet')" heading6 font-700>
            Zákaznická sekce
          </h3>
          <WidgetAuthDialogs default-view="login">
            <h4 :data-test="getCypressId('footer-log-in')" footer-link>
              Přihlásit se
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="register">
            <h4 :data-test="getCypressId('footer-sign-up')" footer-link>
              Registrace
            </h4>
          </WidgetAuthDialogs>
          <WidgetAuthDialogs default-view="forgottenPassword">
            <h4 :data-test="getCypressId('footer-forgot-password')" footer-link>
              Zapomenuté heslo
            </h4>
          </WidgetAuthDialogs>
        </div>
      </div>
    </div>
  </div>
</template>
